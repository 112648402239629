// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-curriculo-index-js": () => import("./../../../src/pages/curriculo/index.js" /* webpackChunkName: "component---src-pages-curriculo-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-servicos-coaching-index-js": () => import("./../../../src/pages/servicos/coaching/index.js" /* webpackChunkName: "component---src-pages-servicos-coaching-index-js" */),
  "component---src-pages-servicos-cultura-clima-organizacionais-index-js": () => import("./../../../src/pages/servicos/cultura_clima_organizacionais/index.js" /* webpackChunkName: "component---src-pages-servicos-cultura-clima-organizacionais-index-js" */),
  "component---src-pages-servicos-gestao-equipe-index-js": () => import("./../../../src/pages/servicos/gestao_equipe/index.js" /* webpackChunkName: "component---src-pages-servicos-gestao-equipe-index-js" */),
  "component---src-pages-servicos-index-js": () => import("./../../../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-servicos-inteligencia-emocional-index-js": () => import("./../../../src/pages/servicos/inteligencia_emocional/index.js" /* webpackChunkName: "component---src-pages-servicos-inteligencia-emocional-index-js" */),
  "component---src-pages-servicos-internacionalizacao-executivos-index-js": () => import("./../../../src/pages/servicos/internacionalizacao_executivos/index.js" /* webpackChunkName: "component---src-pages-servicos-internacionalizacao-executivos-index-js" */),
  "component---src-pages-servicos-lideranca-index-js": () => import("./../../../src/pages/servicos/lideranca/index.js" /* webpackChunkName: "component---src-pages-servicos-lideranca-index-js" */),
  "component---src-pages-servicos-mediacao-conflitos-index-js": () => import("./../../../src/pages/servicos/mediacao_conflitos/index.js" /* webpackChunkName: "component---src-pages-servicos-mediacao-conflitos-index-js" */),
  "component---src-pages-servicos-mexico-brasil-index-js": () => import("./../../../src/pages/servicos/mexico_brasil/index.js" /* webpackChunkName: "component---src-pages-servicos-mexico-brasil-index-js" */),
  "component---src-pages-servicos-mudanca-vida-carreira-index-js": () => import("./../../../src/pages/servicos/mudanca_vida_carreira/index.js" /* webpackChunkName: "component---src-pages-servicos-mudanca-vida-carreira-index-js" */),
  "component---src-pages-servicos-rh-estrategico-index-js": () => import("./../../../src/pages/servicos/rh_estrategico/index.js" /* webpackChunkName: "component---src-pages-servicos-rh-estrategico-index-js" */),
  "component---src-pages-sobre-index-js": () => import("./../../../src/pages/sobre/index.js" /* webpackChunkName: "component---src-pages-sobre-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

